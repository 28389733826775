<template>
  <div v-if="employee" class="forms-elements">
    <Breadcrumbs v-bind:breadcrumbs="breadcrumbs" />

    <Widget v-if="employee">
      <b-row>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>Basisdaten</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('firstname')"
            label-for="firstname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.firstname" type="text" :placeholder="$t('firstname')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('lastname')"
            label-for="lastname"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.lastname" type="text" :placeholder="$t('lastname')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('email')"
            label-for="email"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.email" type="text" :placeholder="$t('email')" />
          </b-form-group>

          <b-form-group
            horizontal
            :label="$t('phone')"
            label-for="phone"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <b-form-input v-model="employee.phone" type="text" :placeholder="$t('phone')" />
          </b-form-group>
        </b-col>
        <b-col lg="6" md="6" sm="12">
          <legend>
            <strong>{{ $t('state') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('state')"
            label-for="activeState"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              :placeholder="$t('state') + ' ' + $t('select')"
              v-model="employee.activeState"
              :options="getEnumValues('ActiveState')"
              :show-labels="true"
              :allow-empty="false"
              :taggable="true"
              :selectLabel="$t('select')"
              :selectedLabel="$t('selected')"
              :deselectLabel="$t('remove_selection')"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><b-badge :variant="getActiveStateColor(option)">{{
                  $t(option)
                }}</b-badge></template
              >
              <template slot="option" slot-scope="{ option }">
                <b-badge :variant="getActiveStateColor(option)">
                  {{ $t(option) }}
                </b-badge></template
              >
            </multiselect>
          </b-form-group>

          <legend>
            <strong>{{ $t('users') }}</strong>
          </legend>

          <b-form-group
            horizontal
            :label="$t('users')"
            label-for="activeState"
            label-class="text-md-right"
            :label-cols="4"
            breakpoint="md"
          >
            <multiselect
              class="multiselect-users"
              v-model="employee.users"
              :options="filteredUsers"
              track-by="number"
              v-on:search-change="onFilterUsers"
              v-on:select="onSelectUser"
              v-on:remove="onSelectUser"
              :filter="true"
              :internal-search="false"
              :close-on-select="false"
              :clear-on-select="false"
              :multiple="true"
              :show-labels="false"
              :allow-empty="true"
              :deselectLabel="$t('deselectLabel')"
              selectLabel=""
              selectedLabel=""
              :placeholder="'Benutzer ' + $t('select') + '/suchen'"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="option" slot-scope="{ option }">
                {{ option.firstname }} {{ option.lastname }} - {{ option.email }}
              </template>
              <template slot="tag" slot-scope="{ option }">
                <div>{{ option.firstname }} {{ option.lastname }} - {{ option.email }}</div>
              </template>
              <template slot="noResult">
                <div>{{ $t('no_results') }}</div>
              </template>
            </multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="12" md="12" sm="12">
          <ButtonBar
            :showDeleteButton="buttons.showDeleteButton"
            :model="employee"
            modelEditRoute="EmployeeEditPage"
            modelListRoute="EmployeesPage"
            modelRouteParamName="employeeNumber"
            :updateCallback="updateEmployee"
            :createCallback="createEmployee"
            :deleteCallback="deleteEmployee"
            :fetchPaginatedCallback="fetchEmployees"
          />
        </b-col>
      </b-row>
    </Widget>
  </div>
</template>

<script>
import Widget from '@/components/Widget/Widget';
import Breadcrumbs from '@/components/Breadcrumbs';
import { mapGetters, mapActions } from 'vuex';
import Multiselect from 'vue-multiselect';
import ButtonBar from '@/components/ButtonBar.vue';
import { getActiveStateColor } from '@/helpers/colors';

export default {
  name: 'EmployeeEditPage',
  components: {
    Widget,
    Breadcrumbs,
    Multiselect,
    ButtonBar,
  },
  props: {
    employeeNumber: {
      required: false,
      default: null,
    },
  },
  data() {
    return {
      number: null,
      filteredUsers: [],
    };
  },
  computed: {
    ...mapGetters(['getEmployee', 'getEmployeeByNumber', 'getEnumValues', 'getUsers']),
    buttons() {
      return {
        showDeleteButton: this.employee && this.employee.number !== '_new' ? true : false,
      };
    },
    employee() {
      return this.getEmployeeByNumber(this.number);
    },
    breadcrumbs() {
      return [
        { name: 'Home', route: { name: 'home' } },
        { name: this.$t('employee'), route: { name: 'EmployeesPage' } },
        { name: this.employee.number !== '_new' ? this.$t('edit') : this.$t('new') },
      ];
    },
  },
  methods: {
    ...mapActions([
      'fetchEmployees',
      'fetchEmployeeByNumber',
      'updateEmployee',
      'createEmployee',
      'deleteEmployee',
      'fetchEnumValues',
      'initEmployee',
      'fetchUsers',
    ]),
    /**
     *
     */
    async onFilterUsers(query) {
      console.log(query);
      this.filteredUsers = [];
      this.getUsers.map((user) => {
        if (
          (user.firstname && user.firstname.toLowerCase().indexOf(query) >= 0) ||
          (user.lastname && user.lastname.toLowerCase().indexOf(query) >= 0)
        ) {
          console.log(user);
          this.filteredUsers.push(user);
        }
      });
    },
    /**
     *
     */
    async onSelectUser(user) {
      console.log(user);
      // const { data } = user;
      // this.save(data);
    },

    /**
     *
     */
    // async save(employee) {
    //   if (employee.number === '_new') {
    //     await this.createEmployee(employee);
    //   } else {
    //     await this.updateEmployee(employee);
    //   }
    // },

    getActiveStateColor,
  },
  async mounted() {
    await this.fetchEnumValues('ActiveState');
    await this.fetchUsers();

    if (!this.employee && this.employeeNumber) {
      this.number = this.employeeNumber;
      await this.fetchEmployeeByNumber(this.number);
    } else {
      this.initEmployee();
      this.number = '_new';
    }

    this.filteredUsers = this.getUsers;
  },
};
</script>

<style scoped lang="scss"></style>
